import desk from '@/views/sys/desk.vue'

export default [
	{
		path: '/',
		name: 'index',
		redirect: '/home',
		component: desk,
		children: [
			{
				path: '/home',
				name: 'home',
				component: () => import('@/views/home.vue')
			},
			{
				path: '/about',
				name: 'about',
				component: () => import('@/views/about.vue')
			}
		]
	}
]