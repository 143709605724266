import { createRouter, createWebHashHistory } from 'vue-router'
import home from './path/home.js'

const routes = [].concat(home)

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
